<template>
  <div class="AccountMembershipView">
    <h1>{{ $t('title') }}</h1>

    <h2>{{ $t('accountInfo') }}</h2>
    <div
      class="btn"
      @click="editAccount=true">
      {{ $t('modify') }}
    </div>
    <template v-if="!editAccount">
      <div
        v-for="user in usersList"
        :key="user.id">
        {{ user.firstName }}
        {{ user.lastName }}
        {{ user.email }}
      </div>
    </template>
    <div v-if="editAccount">
      <form
        v-for="user in usersList"
        :key="user.id"
        @submit.prevent="updateAccount(user.id, user.firstName, user.lastName, user.email)">
        <FormGroup
          v-model.trim="user.firstName"
          :label="$t('firstName')" />
        <FormGroup
          v-model.trim="user.lastName"
          :label="$t('lastName')" />
        <FormGroup
          v-model.trim="user.email"
          :label="$t('email')" />
        <p
          v-if="updateAccountErrors"
          role="error"
          class="alert-danger">
          <ul>
            <li
              v-for="error in updateAccountErrors"
              :key="error">
              {{ error }}
            </li>
          </ul>
        </p>
        <SubmitButton
          class="submitButton"
          :label="$t('update')"
          :working="isProcessing" />
        [<span @click="editAccount=false">{{ $t('cancel') }}</span>]
      </form>
    </div> <!-- editAccount -->

    <h2>{{ $t('paymentMethod') }}</h2>
    <div
      class="btn"
      @click="showCard=true">
      {{ $t('modify') }}
    </div>

    <div v-if="showCard">
      <form @submit.prevent="updatePaymentMethod()">
        <StripeCard ref="card" />
        <p
          v-if="paymentError"
          role="error"
          class="alert-danger">
          {{ paymentError }}
        </p>
        <SubmitButton
          class="submitButton"
          :label="$t('update')"
          :working="isProcessing" />
        <div @click="showCard=false">
          [{{ $t('cancel') }}]
        </div>
      </form>
    </div> <!-- showCard -->

    <table>
      <tr>
        <th>{{ $t('firstName') }}</th>
        <th>{{ $t('lastName') }}</th>
        <th>{{ $t('cardType') }}</th>
        <th>{{ $t('cardNumber') }}</th>
        <th>{{ $t('expiry') }}</th>
        <th>{{ $t('status') }}</th>
      </tr>
      <tr
        v-for="card in cardsList"
        :key="card.data.last4">
        <td>{{ card.data.first_name }}</td>
        <td>{{ card.data.last_name }}</td>
        <td>{{ card.data.brand }}</td>
        <td>{{ card.data.masked_number }}</td>
        <td>{{ card.data.expiry_month }}/{{ card.data.expiry_year }}</td>
        <td>{{ $t(card.data.status) }}</td>
      </tr>
    </table>

    <h2>{{ $t('paymentHistory') }}</h2>
    <table>
      <tr>
        <th>{{ $t('status') }}</th>
        <th>{{ $t('date') }}</th>
        <th>{{ $t('invoiceNo') }}</th>
        <th>{{ $t('amount') }}</th>
        <th />
      </tr>
      <tr
        v-for="invoice in invoicesList"
        :key="invoice.id">
        <td>{{ invoice.data.status }}</td>
        <td>{{ invoice.data.date }}</td>
        <td>{{ invoice.data.id }}</td>
        <td>{{ invoice.data.amount_adjusted }} betalt {{ invoice.data.paid_at }}</td>
        <td><a :href="pdf(invoice.data.id)">{{ $t('downloadPdf') }}</a></td>
      </tr>
    </table>

    <h2>{{ $t('subscriptions') }}</h2>
    <table>
      <tr>
        <th>{{ $t('status') }}</th>
        <th>{{ $t('plan') }}</th>
        <th />
        <th />
      </tr>
      <tr
        v-for="subscription in subscriptionsList"
        :key="subscription.id">
        <td>{{ $t(subscription.data.status) }}</td>
        <td>{{ planName(subscription.data.plan_id) }}</td>
        <td>
          <button @click="showCancel=true">
            {{ $t('cancelSubscription') }}
          </button>
        </td>
        <td>
          <button @click="showPlans=true">
            {{ $t('modifySubscription') }}
          </button>
        </td>
      </tr>
    </table>

    <div
      v-if="showPlans"
      class="plans">
      <form @submit.prevent="updateSubscriptionPlan()">
        <PlanItem
          v-for="p of plans"
          :key="p.id"
          class="Plan"
          :selected="p.id === selectedPlanId"
          v-bind="p"
          @click.native="selectPlan(p)" />

        <p
          v-if="subscriptionError"
          role="error"
          class="alert-danger">
          {{ subscriptionError }}
        </p>
        <SubmitButton
          class="submitButton"
          label="Oppdater"
          :working="isProcessing" />
        <div @click="showPlans=false">
          [Avbryt]
        </div>
      </form>
    </div> <!-- showPlans -->

    <div v-if="showCancel">
      <form @submit.prevent="cancelSubscription()">
        {{ $t('cancelWhen') }}
        <input
          id="cancel-immediately"
          v-model="end_of_term"
          type="radio"
          :value="false">
        <label for="cancel-immediately">{{ $t('cancelImmediately') }}</label>

        <input
          id="cancel-end-of-term"
          v-model="end_of_term"
          type="radio"
          :value="true">
        <label for="cancel-end-of-term">{{ $t('cancelRenewal') }}</label>
        <p
          v-if="cancelError"
          role="error"
          class="alert-danger">
          {{ cancelError }}
        </p>
        <SubmitButton
          class="submitButton"
          :label="$t('cancelSubscription')"
          :working="isProcessing" />
        <div @click="showCancel=false">
          [{{ $t('cancel') }}]
        </div>
      </form>
    </div> <!-- showCancel -->
  </div>
</template>

<translations>
  title: Your membership
  title_no: Ditt medlemskap
  accountInfo: Account information
  accountInfo_no: Kontoinformasjon
  modify: Modify
  modify_no: Endre
  firstName: First name
  firstName_no: Fornavn
  lastName: Last name
  lastName_no: Etternavn
  email: Email
  email_no: Epost
  update: Update
  update_no: Oppdater
  cancel: Cancel
  cancel_no: Avbryt
  paymentMethod: Payment method
  paymentMethod_no: Betalingsmetode
  cardType: Card type
  cardType_no: Korttype
  cardNumber: Card number
  cardNumber_no: Kortnummer
  expiry: Expiry
  expiry_no: Utløpsdato
  status: Status
  status_no: Status
  paymentHistory: Payment history
  paymentHistory_no: Betalingshistorikk
  date: Date
  date_no: Dato
  invoiceNo: Invoice number
  invoiceNo_no: Fakturanummer
  amount: Amount
  amount_no: Beløp
  downloadPdf: Download pdf
  downloadPdf_no: Last ned pdf
  subscriptions: Subscriptions
  subscriptions_no: Abonnement
  plan: Plan
  plan_no: Type
  cancelSubscription: Cancel subscription
  cancelSubscription_no: Kanseller
  modifySubscription: Change subscription
  modifySubscription_no: Endre abonnement
  active: active
  active_no: aktivt
  cancelWhen: When do you want to cancel?
  cancelWhen_no: Når ønsker du å avslutte?
  cancelImmediately: Cancel immediately
  cancelImmediately_no: Kanseller umiddelbart
  cancelRenewal: Cancel on next renewal
  cancelRenewal_no: Kanseller før start av neste periode
  valid: valid
  valid_no: gyldig
  expiring: expiring
  expiring_no: utløper
  expired: expired
  expired_no: utløpt
</translations>

<script>
import apolloClient from '@/apollo-client'
import StripeCard from '../components/StripeCard'
import SubmitButton from '../components/SubmitButton'
import cbPlans from './AccountCheckoutView/plans'
import PlanItem from './AccountCheckoutView/PlanItem'
import FormGroup from '../components/FormGroup'
import MembershipGql from './Membership.gql'
import UserMutationGql from './UserMutation.gql'
import settings from '@/settings'

export default {
  components: { StripeCard, SubmitButton, PlanItem, FormGroup },
  data () {
    return {
      'currency': 'nok',
      'usersList': [],
      'invoicesList': [],
      'subscriptionsList': [],
      'cardsList': [],
      'showCard': false,
      'isProcessing': false,
      'paymentError': '',
      'showPlans': false,
      'selectedPlan': null,
      'subscriptionError': '',
      'showCancel': false,
      'end_of_term': true,
      'cancelError': '',
      'editAccount': false,
      'updateAccountErrors': []
    }
  },
  computed: {
    invalidCard () {
      if (this.$refs.card) {
        return this.$refs.card.$v.$invalid
      }
      return true
    },

    plans () {
      return cbPlans.filter(p => { return p.currency === this.currency })
    },

    selectedPlanId () {
      if (this.selectedPlan) {
        return this.selectedPlan.id
      }
      return null
    }
  },

  mounted () {
    this.currency = this.$store.state.moduleAuth.profile.currency
    this.queryData()
  },
  methods: {
    pdf (invoiceId) {
      return `${settings.endpoints.bouncer}/invoice/${invoiceId}/download`
    },

    planName (id) {
      const plans = cbPlans.filter(p => { return p.id === id })
      if (plans.length) {
        return plans[0].name
      }
      return id
    },

    updateAccount (id, firstName, lastName, email) {
      if (this.isProcessing) { return }
      this.isProcessing = true
      this.updateAccountErrors = []

      // do mutation
      apolloClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: UserMutationGql,
        variables: { firstName, lastName, email, id }
      })
        .then(result => {})
        .then(this.queryData)
        .then(() => { this.editAccount = false })
        .catch(e => {
          this.updateAccountErrors = e.graphQLErrors.map(({ message }) => { return message })
        })
        .finally(() => {
          this.isProcessing = false
        })
    },

    selectPlan (plan) {
      this.selectedPlan = plan
    },

    cancelSubscription () {
      if (this.isProcessing) {
        return
      }
      this.isProcessing = true
      let data = { 'end_of_term': this.end_of_term }
      this.$store.dispatch('moduleAuth/cancelSubscription', data)
        .then(() => {
          window.dataLayer.push({
            'event': 'cancel_subscription'
          })
        })
        .then(this.queryData)
        .then(() => { this.showCancel = false })
        .catch(error => {
          if (error === undefined || error.data === undefined) {
            this.cancelError = 'an error occured, please try again later'
          } else {
            this.cancelError = error.data.error
          }
        })
        .finally(() => {
          this.isProcessing = false
        })
    },

    updateSubscriptionPlan () {
      if (this.isProcessing || !this.selectedPlan) {
        return
      }
      this.subscriptionError = ''
      this.isProcessing = true
      let data = {
        'subscription': { 'plan_id': this.selectedPlanId, 'trial': false }
      }
      this.$store.dispatch('moduleAuth/subscribe', data)
        .then(() => {
          window.dataLayer.push({
            'event': 'change_plan',
            'plan': this.selectedPlan.id,
            'price': this.selectedPlan.price
          })
        })
        .then(this.queryData)
        .then(() => { this.showPlans = false })
        .catch(error => {
          if (error === undefined || error.data === undefined) {
            this.subscriptionError = 'an error occured, please try again later'
          } else {
            this.subscriptionError = error.data.error
          }
        })
        .finally(() => {
          this.isProcessing = false
        })
    },

    updatePaymentMethod () {
      this.paymentError = ''
      this.isProcessing = true
      return this.$refs.card.addPaymentSource()
        .then(() => { this.showCard = false })
        .then(this.queryData)
        .catch(error => {
          if (this.$refs.card.$v.$invalid) {
            // pass on validation errors
          } else if (error === undefined || error.data === undefined) {
            this.paymentError = 'an error occured, please try again later'
          } else {
            this.paymentError = error.data.error
          }
        })
        .finally(() => {
          this.isProcessing = false
        })
    },

    queryData () {
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: MembershipGql
      }).then(result => {
        this.usersList = result.data.usersList
        this.invoicesList = result.data.invoicesList
        this.subscriptionsList = result.data.subscriptionsList
        this.cardsList = result.data.cardsList
      })
    }
  }
}

</script>

<style lang="scss" scoped>
</style>
