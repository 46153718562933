<template>
  <div
    class="PlanItem"
    :class="{ 'is-selected': selected }">
    <div class="main">
      <span class="currencyPrefix">{{ currencySymbol }}</span>
      <span
        v-if="price > 0"
        class="price">{{ price }}</span>
      <span class="period">/{{ $t(`${planType}`) }}</span>
    </div>
    <div
      v-if="bestValue"
      class="bestValue">
      {{ $t(`${youSave}`) }}
    </div>
  </div>
</template>

<translations>
  month: monthly
  month_no: måned
  annual: year
  annual_no: år
  2months: You save 2 months
  2months_no: Du sparer 2 måneder
  free: Free forever
  free_no: Gratis for alltid
</translations>

<script>
export default {
    props: {
        selected: {
            type: Boolean,
            default: false
        },

        id: String,
        price: String,
        period: String,
        planType: String,
        tag: String,
        currency: String,
        youSave: String,
        bestValue: Boolean
    },
    computed: {
        currencyPrefix() {
            return { usd: '$', nok: 'kr' }[this.currency];
        },
        currencySymbol() {
            return { usd: '$', nok: 'kr' }[this.currency];
        }
    }
};
</script>

<style lang="scss" scoped>
.PlanItem {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6em;
    border: 2px solid mix(white, black, 95%);
    border-radius: 4px;

    &.is-selected {
        background-color: rgba($greenButtonBackground, 0.1);
        border-color: $greenButtonBackground;
    }

    &:not(.is-selected) {
        &:first-child {
            border-radius: 4px 0px 0px 4px;
            border-right: none;
        }
        &:last-child {
            border-radius: 0px 4px 4px 0px;
            border-left: none;
        }
    }
}
.main {
}
.currencyPrefix {
    color: rgba(black, 0.5);
}
.price {
    font-size: 140%;
    color: rgba(black, 0.6);
}
.period {
    color: rgba(black, 0.5);
}
.bestValue {
    position: absolute;
    bottom: 0.5em;
    left: 0;
    width: 100%;
    text-align: center;
    color: mix($greenButtonBackground, black, 90%);
    span {
        font-size: 80%;
    }
}
</style>
