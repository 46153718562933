<template>
  <div class="StripeCard">
    <fieldset
      v-show="expressCheckoutIsAvailable || showDummyExpress"
      class="section-billing-information section-express-checkout">
      <div class="legend">
        <span
          v-t="'expressCheckout'"
          class="label" />
        <span class="icon-google-pay" />
        <span class="icon-apple-pay" />
      </div>
      <div
        id="payment-request-button"
        ref="express" />
      <div v-show="showDummyExpress">
        {{ token }}
        <div
          class="button"
          @click="dummyExpress()">
          dummy express checkout
        </div>
      </div>
    </fieldset>

    <fieldset class="section-billing-information">
      <div class="legend">
        <span
          v-t="'account.AccountCheckoutView.payment.legend'"
          class="label" />
        <span class="icon-visacard" />
        <span class="icon-mastercard" />
      </div>

      <FormGroup
        v-model="cardholderName"
        :label="$t('account.AccountCheckoutView.payment.name')"
        name="nameoncard"
        :v="$v.cardholderName"
        autocomplete="cc-name" />

      <div class="form-group">
        <label for="card-element" />
        <div
          id="card-errors"
          role="alert">
          {{ cardError }}
        </div>
        <div
          id="card-element"
          ref="card" />
      </div>
    </fieldset>
    <fieldset class="section-billing-address">
      <div
        v-t="'account.AccountCheckoutView.billingAddress.legend'"
        class="legend" />

      <FormGroup
        v-model="billingAddressLine1"
        :label="$t('account.AccountCheckoutView.billingAddress.line1')"
        name="street-address"
        :v="$v.billingAddressLine1"
        autocomplete="street-address" />

      <FormGroup
        v-model="billingAddressZip"
        :label="$t('account.AccountCheckoutView.billingAddress.zip')"
        name="postal-code"
        :v="$v.billingAddressZip"
        autocomplete="postal-code" />

      <div class="form-group">
        <label class="Formgroup__label" />
        <select
          v-model="billingAddressCountry"
          name="country"
          class="select-country"
          style="width: 100%;"
          autocomplete="country">
          <option
            v-t="'account.AccountCheckoutView.billingAddress.blankCountryOption'"
            disabled
            selected
            value />
          <option
            v-for="country of countries()"
            :key="country.countryCode"
            :value="country.countryCode">
            {{ country.label }}
          </option>
        </select>
      </div>
    </fieldset>
  </div>
</template>
<translations>
  cardRequired: Card number is required
  cardRequired_no: Kortnummer er påkrevd
  expressCheckout: Express Checkout
  expressCheckout_no: Hurtigkasse
</translations>

<script>
/* global Stripe */
import countriesEN from '../views/AccountCheckoutView/countries_en.json';
import countriesNO from '../views/AccountCheckoutView/countries_no.json';
import { required } from 'vuelidate/lib/validators';
import FormGroup from './FormGroup';

const settings = {
    stripeKey:
        process.env.NODE_ENV === 'production' ? 'pk_live_3IGJONsFjXf6EdRhbZ67sJ6I' : 'pk_test_UP3rM5NpZTMEZMB1yT9tC4AZ'
};

export default {
    components: { FormGroup },
    // eslint-disable-next-line
    props: ['product', 'amount', 'currency', 'plan'],

    data() {
        return {
            cardholderName: '',
            billingAddressLine1: '',
            billingAddressCountry: '',
            billingAddressZip: '',
            stripeInputComplete: false,
            cardError: '',
            expressCheckoutIsAvailable: false,
            token: { id: null },
            paymentRequest: null
        };
    },
    computed: {
        showDummyExpress() {
            return window.location.href.startsWith('http://localhost');
        },
        paymentRequestData() {
            return {
                currency: this.currency,
                total: {
                    label: this.product,
                    amount: parseInt(this.amount) * 100
                }
            };
        }
    },
    watch: {
        $props: {
            handler() {
                if (this.paymentRequest !== null) {
                    this.paymentRequest.update(this.paymentRequestData);
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.setupStripe();
    },
    beforeDestroy() {
        this.unmountStripe();
    },
    methods: {
        async dummyExpress() {
            this.token.id = 'tok_visa';
            this.$store
                .dispatch('moduleAuth/addPaymentSource', this.token)
                .then(() => {
                    console.log('emit event express-checkout');
                    this.$emit('express-checkout');
                })
                .catch(err => {
                    console.error(err);
                    this.token.id = null;
                });
        },
        countries() {
            const countriesMap = {
                en: countriesEN,
                no: countriesNO
            }[this.$store.getters['moduleApp/locale']];

            return Object.entries(countriesMap)
                .map(([key, val]) => {
                    return {
                        countryCode: key,
                        label: val
                    };
                })
                .sort((a, b) => {
                    return a.label > b.label ? 1 : -1;
                });
        },
        unmountStripe() {
            if (this.card) {
                this.card.unmount();
            }
        },
        setupCardData() {
            return {
                name: this.cardholderName,
                address_line1: this.billingAddressLine1,
                address_line2: '',
                address_city: '',
                address_state: '',
                address_zip: this.billingAddressZip,
                address_country: this.billingAddressCountry
            };
        },
        createStripeToken() {
            let cardData = this.setupCardData();
            return this.stripe.createToken(this.card, cardData).then(stripeResult => {
                if (stripeResult.error) {
                    return Promise.reject(new Error(stripeResult.error));
                }
                return Promise.resolve(stripeResult.token);
            });
        },

        addPaymentSource() {
            console.debug('addPaymentSource()');
            if (this.token.id) {
                return Promise.resolve(this.token);
            }
            this.paymentError = false;
            this.backendError = '';
            this.cardError = '';
            this.$v.$touch();
            if (this.$v.$invalid) {
                if (this.$v.stripeInputComplete.required === false) {
                    this.cardError = this.$t('cardRequired');
                }
                this.$refs.card.className += ' StripeElement--invalid';
                return Promise.reject(new Error(this.cardError));
            }
            return this.createStripeToken().then(token => {
                this.token.id = token.id;
                return this.$store.dispatch('moduleAuth/addPaymentSource', token).catch(err => {
                    this.token.id = null;
                    return Promise.reject(err);
                });
            });
        },

        createPaymentMethod() {
            console.log('createPaymentMethod()');
            return this.validateFields()
                .then(this.paymentMethodCardData)
                .then(cardData => this.stripe.createPaymentMethod('card', this.card, cardData))
                .then(result => this.handleCreatePaymentMethod(result))
                .then(paymentRequest => this.handlePaymentRequest(paymentRequest))
                .then(response => this.handleCardAction(response.data))
                .then(confirmResult => this.handleConfirmResult(confirmResult))
                .then(confirmResult => confirmResult.data.id);
        },

        validateFields() {
            this.paymentError = false;
            this.backendError = '';
            this.cardError = '';
            this.$v.$touch();
            if (this.$v.$invalid) {
                if (this.$v.stripeInputComplete.required === false) {
                    this.cardError = this.$t('cardRequired');
                }
                this.$refs.card.className += ' StripeElement--invalid';
                return Promise.reject(new Error(this.cardError));
            }
            return Promise.resolve();
        },

        async paymentMethodCardData() {
            return {
                billing_details: {
                    name: this.cardholderName,
                    address: {
                        city: '',
                        country: this.billingAddressCountry,
                        line1: this.billingAddressLine1,
                        postal_code: this.billingAddressZip
                    }
                }
            };
        },

        handleCreatePaymentMethod(result) {
            console.log('handleCreatePaymentMethod()', result);
            if (result.error) {
                return Promise.reject(new Error(result.error));
            }
            return {
                payment_method: result.paymentMethod,
                subscription: { plan_id: this.plan },
                currency: this.currency
            };
        },

        handlePaymentRequest(paymentRequest) {
            console.log('handlePaymentRequest', paymentRequest);
            return this.$store.dispatch('moduleAuth/paymentIntent', paymentRequest);
        },

        handleCardAction(result) {
            console.log('handleCardAction', result);
            if (result.payment_intent_client_secret) {
                return this.stripe
                    .handleCardAction(result.payment_intent_client_secret)
                    .then(result => this.authenticate(result));
            } else if (result.success) {
                return Promise.resolve({ data: { id: result.payment_intent_id } });
            }
            return Promise.reject(new Error(result.error.message));
        },

        authenticate(result) {
            console.log('authenticate', result);
            if (result.error) {
                // There was an error with the 2FA
                this.cardError = result.error.message;
                console.error(result.error);
                return Promise.reject(new Error(result.error.message));
            } else {
                // The card action has been handled
                // The PaymentIntent can be confirmed again on the server
                return this.confirmPayment(result.paymentIntent.id);
            }
        },

        confirmPayment(paymentIntentId) {
            console.log('confirmPayment', paymentIntentId);
            return this.$store.dispatch('moduleAuth/confirmPaymentIntent', paymentIntentId).catch(err => {
                this.cardError = err.response.data.error;
                return Promise.reject(err);
            });
        },

        handleConfirmResult(confirmResult) {
            console.log('handling confirm result', confirmResult.data);
            return confirmResult;
        },

        mountPaymentRequestButton() {
            console.log('mountPaymentRequestButton()');
            this.paymentRequest = this.stripe.paymentRequest({
                country: 'NO',
                requestPayerName: true,
                requestPayerEmail: true,
                ...this.paymentRequestData
            });
            let locale = this.$store.getters['moduleApp/locale'];
            let elements = this.stripe.elements({ locale });
            let prButton = elements.create('paymentRequestButton', { paymentRequest: this.paymentRequest });

            this.paymentRequest
                .canMakePayment()
                .then(result => {
                    this.expressCheckoutIsAvailable = true;
                    prButton.mount('#payment-request-button');
                })
                .catch(err => {
                    console.log(err);
                    console.log('can not make express payment');
                    this.expressCheckoutIsAvailable = false;
                });

            this.paymentRequest.on('token', ev => {
                this.token.id = ev.token.id;
                this.$store
                    .dispatch('moduleAuth/addPaymentSource', ev.token)
                    .then(() => {
                        console.log('success in adding express checkout payment source');
                        ev.complete('success');
                        this.$emit('express-checkout');
                    })
                    .catch(e => {
                        console.error('failed to add express checkout payment source');
                        console.debug(e);
                        ev.complete('fail');
                        this.token.id = null;
                    });
            });
        },
        mountCard() {
            let locale = this.$store.getters['moduleApp/locale'];
            let elements = this.stripe.elements({ locale: locale });
            this.card = elements.create('card', { hidePostalCode: true });
            this.card.mount('#card-element');

            this.card.addEventListener('change', event => {
                if (event.error) {
                    this.cardError = event.error.message;
                } else {
                    this.cardError = '';
                }
                this.stripeInputComplete = event.complete;
            });
        },
        initStripe() {
            console.log('initStripe()');
            this.stripe = Stripe(settings.stripeKey); // eslint-disable-line new-cap
        },
        setupStripe() {
            if (typeof Stripe === 'function') {
                if (!this.stripe || !this.elements) {
                    this.initStripe();
                }
                if (!this.card) {
                    this.mountCard();
                    this.mountPaymentRequestButton();
                }
                return;
            }
            let stripeScript = document.createElement('script');
            stripeScript.src = 'https://js.stripe.com/v3/';
            document.getElementsByTagName('head')[0].appendChild(stripeScript);
            stripeScript.onload = () => {
                this.initStripe();
                this.mountCard();
                this.mountPaymentRequestButton();
            };
        }
    },
    validations() {
        return {
            cardholderName: {
                required
            },
            billingAddressCountry: {
                required
            },
            billingAddressLine1: {
                required
            },
            billingAddressZip: {
                required
            },
            stripeInputComplete: {
                required
            }
        };
    }
};
</script>

<style lang="scss" scoped>
$grayTextColor-dark: #333;
$grayTextColor-medium: #777;
$grayLineColor: #ddd;

// ---------------------------
// Billing Information section
// ---------------------------

.legend {
    font-weight: 600;
}
.section-billing-information {
    margin-bottom: 2em;

    .legend {
        display: flex;

        .label {
            flex: 1;
            margin-bottom: 1.5em;
        }
    }
}

.icon-visacard,
.icon-mastercard {
    display: inline-block;
    width: 2em;
    height: 1.3em;
    background-size: contain;
    background-repeat: no-repeat;
}
.icon-visacard {
    background-image: url('../views/AccountCheckoutView/icon-visacard.svg');
}
.icon-mastercard {
    background-image: url('../views/AccountCheckoutView/icon-mastercard.svg');
    margin-left: 0.5em;
}

.icon-google-pay,
.icon-apple-pay {
    display: inline-block;
    width: 3em;
    height: 1.9em;
    background-size: contain;
    background-repeat: no-repeat;
}

.icon-apple-pay {
    background-image: url('../views/AccountCheckoutView/apple-pay-mark.svg');
    margin-left: 0.5em;
}

.icon-google-pay {
    background-image: url('../views/AccountCheckoutView/google-pay-mark.svg');
    margin-left: 0.5em;
}

// ---------------------------
// Stripe element
// ---------------------------
$errorColor: #b32e28; //red;

#card-element {
    &.StripeElement {
        border: 1px solid $grayLineColor;
        padding: 0.5em 1em;
    }
    &.StripeElement--focus {
        border-color: blue;
    }
    &.StripeElement--invalid {
        $errorColor: #b32e28; //red;
        border: 1px solid darken($errorColor, 20%);
    }
}
#card-errors {
    color: $errorColor;
    font-size: 90%;
    margin-bottom: 0.3em;
}

// ---------------------------
// Address section
// ---------------------------

select {
    appearance: none;
    font: inherit;
    padding: 0.5em 1em;
    background-color: white;
    border-radius: 0px;
    outline: none;
    border: 1px solid $grayLineColor;
    width: 100%;

    background-image: url('../views/AccountCheckoutView/icon-chevron-down.svg');
    background-position: calc(100% - 0.5em) center;
    background-size: 0.8em;
    background-repeat: no-repeat;

    &::-ms-expand {
        /* for IE 11 */
        display: none;
    }
}

fieldset {
    border: none;
    padding: 0px;
    margin: 0px;
}

.FormGroup,
.form-group {
    margin-top: 1em;
}

.button {
    border: 1px solid blue;
    margin: 2px;
    padding: 2px;
}
</style>
